<template>
<div>
    <!-- <div class="body-2 mb-2 primary--text">
        Зверніть увагу! Деякі параметри застарілі (<span class="caption">* deprecated</span>) та будуть видалені після 31/07/2022. На заміну введені нові. Просимо провести переналаштування данних.
    </div> -->
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left" width="30%">
                        Тег
                    </th>
                    <th class="text-left" width="70%">
                        Зміст
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in xml" :key="item.col">
                    <td>
                        {{ item.xml }}
                        <sup v-if="item.deprecated" class="primary--text font-italic">* deprecated</sup>
                        <sup v-if="item.new" class="green--text">НОВЕ</sup>
                    </td>
                    <td>
                        {{ item.name }}<span v-if="item.descr" class="font-italic caption"> ({{ item.descr }})</span>
                        <div v-if="item.value"><b>Значення:</b> {{ item.value }}</div>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
    <div  v-if="regions" class="subtitle-2 my-4 ml-4">ID Регіону Склада<sup class="green--text ml-1">НОВЕ</sup>:</div>
    <v-simple-table v-if="regions">
        <thead>
            <tr>
                <th class="text-left" width="30%">
                    ID
                </th>
                <th class="text-left" width="70%">
                    Назва Регіону
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(v, key) in regions" :key="key">
                <td>
                    {{v.id}}
                </td>
                <td>
                    {{v.name}}
                </td>
            </tr>
        </tbody>
    </v-simple-table>
    <div v-if="contactInfo && contactInfo.availableSatWhs.length" class="subtitle-2 my-4 ml-4">ID Території Склада<sup class="green--text ml-1">НОВЕ</sup>:</div>
    <v-simple-table v-if="contactInfo && contactInfo.availableSatWhs.length">
        <thead>
            <tr>
                <th class="text-left" width="30%">
                    ID
                </th>
                <th class="text-left" width="70%">
                    Назва Території
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(v, key) in contactInfo.availableSatWhs" :key="key">
                <td>
                    {{v.id}}
                </td>
                <td>
                    {{v.name}}
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</div>
</template>

<script>
export default {
    components: {},
    directives: {},
    data: () => ({
        xml: [{
                name: 'Категорія',
                xml: 'cat_top'
            },
            {
                name: 'Код Категорії',
                xml: 'cat_top_id',
            },
            {
                name: 'Група',
                xml: 'cat_2l',
            },
            {
                name: 'Підгрупа',
                xml: 'cat',
            },
            {
                name: 'Код Підгрупи',
                xml: 'cat_id',
            },
            {
                name: 'TM',
                xml: 'brand',
            },
            {
                name: 'Код',
                xml: 'id',
            },
            {
                name: 'Артикул',
                xml: 'artikul',
            },
            {
                name: 'Штрих-код',
                xml: 'barcode',
            },
            {
                name: 'Назва EN',
                xml: 'name',
            },
            {
                name: 'Назва UA',
                xml: 'name_ukr',
            },
            {
                name: 'Стан',
                xml: 'type',
            },
            {
                name: 'Ціна, грн.',
                xml: 'price',
            },
            {
                name: 'Ціна за пакування, грн.',
                xml: 'price_scu',
            },
            {
                name: 'РРЦ, грн.',
                xml: 'rrp',
            },
            {
                name: 'Контроль РРЦ, грн.',
                xml: 'rrp_control',
            },

            // {
            //     name: 'Склад Київ',
            //     xml: 'status_main',
            //     value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
            //     deprecated: true,
            // },
            // {
            //     name: `Залишок Київ, шт.`,
            //     xml: 'qty_main',
            //     deprecated: true,
            // },
            // {
            //     name: `Склад регіона Клієнта`,
            //     xml: 'status_region',
            //     value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
            //     deprecated: true,
            // },
            // {
            //     name: `Залишок складу регіона Клієнта, шт.`,
            //     xml: 'qty_region',
            //     deprecated: true,
            // },

            // {
            //     name: `РЛЦ регіона Клієнта`,
            //     xml: 'status_regionRLC',
            //     value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
            //     deprecated: true,
            // },
            // {
            //     name: `Залишок РЛЦ регіона Клієнта, шт.`,
            //     xml: 'qty_regionRLC',
            //     deprecated: true,
            // },
            // {
            //     name: `Склад сателіта Клієнта`,
            //     xml: 'status_regionSat',
            //     value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
            //     deprecated: true,
            // },
            // {
            //     name: `Залишок складу сателіта Клієнта, шт.`,
            //     xml: 'qty_regionSat',
            //     deprecated: true,
            // },

            {
                name: 'Статус наявності на Регіональному складі',
                // xml: 'status_region_[ID Регіону Склада]',
                xml: 'status_region_[ID Регіону Склада]_code',
                descr: 'тлумачення ID Регіону Склада див. під таблицею',
                value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
                // new: true,
            },
            {
                name: `Залишок на Регіональному складі, шт.`,
                xml: 'qty_region_[ID Регіону Склада]',
                descr: 'тлумачення ID Регіону Склада див. під таблицею',
                // new: true,
            },
            {
                name: 'Статус наявності на РЛЦ складі',
                xml: 'status_regionRLC_[ID Регіону Склада]_code',
                descr: 'тлумачення ID Регіону Склада див. під таблицею',
                value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
                // new: true,
            },
            {
                name: `Залишок на РЛЦ складі, шт.`,
                xml: 'qty_regionRLC_[ID Регіону Склада]',
                descr: 'тлумачення ID Регіону Склада див. під таблицею',
                // new: true,
            },
            {
                name: 'Статус наявності на Територіальному складі',
                xml: 'status_sat_[ID Території Склада]_code',
                descr: 'тлумачення ID Території Склада див. під таблицею',
                value: '0 - немає; 1 - в наявності; 2 - у резерві; 3 - очікується; 4 - під запит',
                // new: true,
            },
            {
                name: `Залишок на Територіальному складі, шт.`,
                xml: 'qty_sat_[ID Території Склада]',
                descr: 'тлумачення ID Території Склада див. під таблицею',
                // new: true,
            },
            {
                name: 'РРЦ (без Промо), грн.',
                xml: 'rrp_woPromo',
            },
            {
                name: 'Ціна без ПДВ, грн.',
                xml: 'price_wout_vat',
            },
            {
                name: 'Ставка ПДВ',
                xml: 'vat_rate',
            },
            {
                name: 'Код ставки ПДВ',
                xml: 'vat_rate_code',
            },
            {
                name: 'Гарантійний термін',
                xml: 'warranty',
            },
            {
                name: 'Країна',
                xml: 'country',
            },
            {
                name: 'Код УКТ',
                xml: 'ukt',
            },
            {
                name: 'У пакуванні, шт.',
                xml: 'scu',
            },
            {
                name: 'У коробці, шт.',
                xml: 'scu_basket',
            },
            {
                name: 'У палеті, шт.',
                xml: 'scu_palette',
            },
            {
                name: 'Вага, кг',
                xml: 'weight',
            },
            {
                name: 'Вага пакування, кг',
                xml: 'weight_scu',
            },
            {
                name: 'Об’єм, м3',
                xml: 'volume',
            },
            {
                name: 'Серія',
                xml: 'line',
            },
            {
                name: 'Опис скорочений',
                xml: 'descr',
            },
            {
                name: 'Опис повний',
                xml: 'url',
            },
            {
                name: 'Фото',
                xml: 'photo',
            },
            {
                name: 'Фото (велике)',
                xml: 'photo_b',
            },
            {
                name: 'Ексклюзив ЮК',
                xml: 'exclusive',
            }
        ]
    }),
    props: {},
    methods: {},
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        regions() {
            const regions = this.$store.state.main.contactInfo && this.$store.state.main.contactInfo.availableRegionWhs
            const regionsDc = this.$store.state.main.contactInfo && this.$store.state.main.contactInfo.availableDcWhs
            regions.push(...regionsDc)
            const out = regions.filter((value, index, self) => index === self.findIndex((t) => (
                t.id === value.id
            )))
            return out
        }
    }
}
</script>

<style>
</style>
