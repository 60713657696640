<template>
<v-container fluid fill-height class="pa-0">

    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `px-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 break-word justify-space-between">
            Прайс-листи :: get-price-ws
        </v-card-title>
        <v-card-text class="px-0">
            <p>Персональний прайс-лист. Дозволяє вивантажувати прайс-лист по обраній категорії каталогу товарів та комбінувати додаткові поля для отримання потрібної інформації по товарам. Варіант отримання прайса дозволяє отримати прайс у форматах - JSON, XLSX та XML.</p>
        </v-card-text>
        <v-card-text class="pa-4 pa-md-6 pb-3 blue lighten-5">
            <p><b>Метод:</b> <b class="primary--text">get-price-ws</b> WebSocket</p>
            <p><b>URL:</b> {{url}}</p>
            <p><b>Формат запиту:</b> WebSocket <b class="primary--text">JSON</b></p>
            <p><b>Формат відповіді:</b> WebSocket <b class="primary--text">JSON, XLSX, XML</b></p>
            <p><b>Доступність:</b> За отриманим Токеном авторизації <b class="primary--text"><a @click="$router.push('/api-services/get-auth-token')">authToken</a></b></p>
        </v-card-text>
        <v-card-text class="px-0">
            <v-row no-gutters>
                <v-col cols="12" md="7" class="py-0 mb-6">
                    <v-simple-table class="mt-4">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" width="25%">
                                        Параметр запиту
                                    </th>
                                    <th class="text-left" width="20%">
                                        Тип даних
                                    </th>
                                    <th class="text-left" width="55%">
                                        Опис
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in params" :key="item.name">
                                    <td :class="item.req ? `primary--text font-weight-bold` : ``">{{ item.name }}
                                        <v-icon v-if="item.req" color="primary" class="mb-3">*</v-icon>
                                    </td>
                                    <td>{{ item.type }}</td>
                                    <td v-html="item.descr"></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-title class="px-0 break-word">
            Приклади реалізації
        </v-card-title>
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <h4>NodeJS</h4>
                <v-row no-gutters>
                    <v-col class="d-flex align-top">
                        <v-textarea dark background-color="grey darken-2" :value="codetext" rows="10" readonly class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(1)" title="Копіювати в буфер обміну" v-clipboard="() => codetext" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[1] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <h4>PHP</h4>
                <v-row no-gutters>
                    <v-col class="d-flex align-top">
                        <v-textarea dark background-color="grey darken-2" :value="codetextPhp" rows="10" readonly class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(2)" title="Копіювати в буфер обміну" v-clipboard="() => codetextPhp" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[2] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-card-title class="px-0 break-word">
            Перевірити роботу методу
        </v-card-title>
        <v-card-text class="px-0">
            <p>Для перевірки методу скористайтеся <b class="primary--text">Конструктором запиту</b>, щоб сформувати коректний запит JSON.</p>
            <p>Перевірка роботи методу доступна за допомогою відповідного плагіну Вашого браузера. (Наприклад, для встановлення плагіна для Chrome перейдіть за посиланням - <a href="https://chrome.google.com/webstore/detail/simple-websocket-client/pfdhoblngboilpfeibdedpjgfnlcodoo?hl=ua" target="_blank">Simple WebSocket Client</a>).</p>
        </v-card-text>
        <h4>Параметри запиту</h4>
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="format*" :value="(format ? `&quot;${format}&quot;` : '')" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="type*" :value="(type ? `&quot;${type}&quot;`:'')" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="authToken" :value="(authToken ? `&quot;${authToken}&quot;`:'')" persistent-hint hint="Токен заповнено для прикладу. Дія токена обмежена 1 годиною." readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="cats" :value="(cats ? '['+cats+']' : '[]')" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="ext_cols" :value="(ext_cols && ext_cols.length > 0 ? '[' + ext_cols.map(el => `&quot;${el}&quot;`) + ']' : '[]')" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="type_prod" :value="(type_prod && type_prod.length > 0 ? '[' + type_prod.map(el => `&quot;${el}&quot;`) + ']' : '[]') " readonly></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-btn color="primary" :disabled="loaded" @click="additionalColumnsDialog = !additionalColumnsDialog">
                            <v-icon>settings</v-icon> <span class="ml-5"> Конструктор запиту</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-10">
                    <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                        <v-textarea no-resize rows="9" outlined :value="requestTxt" :label="`WebSocket Запит ${url}`" type="text" readonly class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(2)" title="Копіювати в буфер обміну" v-clipboard="() => requestTxt" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[2] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-10">
                    <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                        <v-textarea no-resize rows="9" outlined :value="response" label="Приклад відповіді" readonly type="text" class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(3)" title="Копіювати в буфер обміну" v-clipboard="() => response" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[3] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-card-title class="px-0 break-word">
            Тлумачення відповіді
        </v-card-title>
        <XMLfields />
    </v-card>

    <v-dialog v-model="additionalColumnsDialog" max-width="1200">
        <v-card>
            <v-system-bar window light>
                Конструктор запиту
                <v-spacer></v-spacer>
                <v-btn icon @click="additionalColumnsDialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-container>
                <v-row>
                    <v-col class="pb-0">
                        <v-select :items="formatItems" label="Оберіть формат прайсу (format):" v-model="format"></v-select>
                    </v-col>
                    <v-col class="pb-0">
                        <v-select :items="typeItems" label="Оберіть тип прайсу (type):" v-model="type"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select multiple :items="type_prodItems" label="Оберіть стан товару (type_prod):" persistent-hint hint="* якщо не обрано жодного значення, то за замовчуванням буде вивантажено товар зі станом Новий, ДУ" class="mb-10" v-model="type_prod" clearable></v-select>
                        <v-card flat>
                            <h4>
                                Оберіть асортимент для виведення у прайс (cats):
                            </h4>
                            <v-card-text>
                                <v-treeview :open.sync="opened" @input="saveCat()" v-model="selection" :items="items" selectable dense return-object selected-color="primary"></v-treeview>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-divider vertical class="my-6"></v-divider>
                    <v-col>
                        <v-card flat>
                            <h4>
                                Оберіть додаткові дані для виведення у прайс (ext_cols):
                            </h4>
                            <v-card-text>
                                <v-card class="d-flex flex-wrap ml-xs-6 ml-sm-10" flat tile>
                                    <v-card v-for="(item, index) in columns" :key="index" class="pa-0" flat tile :width="$vuetify.breakpoint.smAndUp ? `50%` : `100%`">
                                        <v-switch dense v-model="columns[index].selected" color="primary" @change="saveCols()" :label="item.name"></v-switch>
                                    </v-card>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="additionalColumnsDialog = false" color="primary">Застосувати</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

import XMLfields from '../../XMLFields.vue'

export default {
    components: {
        XMLfields
    },
    directives: {},
    data: () => ({
        opened: [{
            id: 0
        }],
        type_prod: [],
        url: process.env.NODE_ENV === 'production' ? 'wss://price.yugcontract.ua' : 'ws://dev.yugcontract.ua:4000',
        urlAuth: process.env.NODE_ENV === 'production' ? 'https://auth.yugcontract.ua/api/auth/get-auth-token' : 'http://dev.yugcontract.ua:3400/api/auth/get-auth-token',
        copied: {},
        request: `{
  "format": "{{format}}",
  "type": "{{type}}",
  "authToken": "{{authToken}}",
  "cats": [{{cats}}],
  "ext_cols": [{{ext_cols}}],
  "type_prod": [{{type_prod}}]
}
`,
        additionalColumnsDialog: false,
        fileBlobURL: '',
        closed: false,
        loaded: false,
        fileName: '',
        format: '',
        selection: [],
        items: [],
        show1: true,
        params: [{
                name: 'authToken',
                type: 'String',
                req: true,
                descr: 'Токен авторизації'
            },
            {
                name: 'format',
                type: 'String',
                req: true,
                descr: 'Формат відповіді.<br><b>Значення:</b><br><ul><li>xml</li><li>xlsx</li><li>json</li></ul>'
            },
            {
                name: 'type',
                type: 'String',
                req: true,
                descr: 'Тип прайс-листа.<br><b>Значення:</b><br><ul><li>regular - Звичайний,</li><li>bank - Безготівковий,</li><li>rrc - Контроль РРЦ</li></ul>'
            },
            {
                name: 'cats',
                type: 'Array',
                descr: 'Перелік категорій товарів.<br><b>Значення:</b><br><ul><li>id категорій</li></ul>Параметр надає можливість керувати обмеженням стандартного вивантаження всього каталогу. Вивантажуються вказані у параметрі категорії та вкладені у них. У разі пустого значення - буде вивантажено весь каталог.<br>Сформувати необхідний формат можна за допомогою <b>Конструктора запиту</b>'
            },
            {
                name: 'ext_cols',
                type: 'Array',
                descr: 'Перелік додаткових полів.<br><b>Значення:</b><br><ul><li>службові назви полів</li></ul>Параметр надає можливість керувати вибором додаткових даних по товарам у прайс. У разі пустого значення - додаткові поля додаватися у прайс не будуть.<br>Сформувати необхідний формат можна за допомогою <b>Конструктора запиту</b>'
            },
            {
                name: 'type_prod',
                type: 'Array',
                req: false,
                descr: 'Стан<br><b>Значення:</b><br><ul><li>new - Новий</li><li>du - ДУ</li><li>priceoff - Уцінка</li></ul>Сформувати необхідний формат можна за допомогою <b>Конструктора запиту</b>'
            },
        ],
        codeTxtPhp: `### Втрановити composer з https://getcomposer.org/download/
### Створити файл composer.json додати в нього

/*
json
{
    "name": "vendor_name/wss",
    "description": "description",
    "require": {
      "firebase/php-jwt": "^5.2",
      "ext-json": "*",
      "ratchet/pawl": "^0.3.5",
      "divineomega/php-cli-progress-bar": "^2.1"
    }
}
*/

#### В коренi виконати команду 'composer install'

#### Створити файл 'exemple.php' та пiдключити бiблiотеки

<?php
require __DIR__ . '/vendor/autoload.php';

use \\Firebase\\JWT\\JWT;
use Ratchet\\RFC6455\\Messaging\\MessageInterface;
use function Ratchet\\Client\\connect;

try {
    $max = 5;

    $progressBar = new DivineOmega\\CliProgressBar\\ProgressBar;
    $progressBar->setMaxProgress($max);

    echo "Authorization... \\n\\r";
    $progressBar->advance()->display();
    echo "\\n\\r";

    //Variables
    $format = '{{format}}';
    $type = '{{type}}';
    $type_prod = [{{type_prod}}];
    $ext_cols = [{{ext_cols}}];
    $cats = [{{cats}}];
    $filePath = './files/';
    $fileName = $filePath . $type . '_' . date('d-m-Y_H:i:s') . '_price.' . $format;
    $wsURL = '{{url}}';

    //-----------------------------------------------------------------------

    $auth = new Auth(); // Initialize class Auth
    $result = $auth->getAuthToken(); // Send auth query in API
    $authToken = $result->content->authToken; // Take token

    // Connect to wss
    connect($wsURL)->then(function ($conn) use ($fileName, $auth, $type, $format, $ext_cols, $cats, $authToken, $progressBar) {
        echo "Connect\\n\\r";
        echo "WebSocket Client Connected\\n\\r";
        $progressBar->advance()->display();
        echo "\\n\\r";

        $conn->on('connectFailed', function ($msg) use ($conn) {
            echo "Connect Error: {$msg}\\n\\r";
            $conn->close();
        });

        $conn->on('error', function ($error) use ($conn) {
            echo "Connect Error: {$error}\\n\\r";
            $conn->close();
        });

        $conn->on('close', function () use ($conn) {
            echo "echo-protocol Connection Closed\\n\\r";
            $conn->close();
        });

        $conn->on('message', function(MessageInterface $message) use ($conn, $fileName, $progressBar) {
            //If have content
            if (!empty($message->getPayload())) {
                // Save in json file
                $fp = fopen($fileName, 'w+');
                fwrite($fp, $message->getPayload());
                fclose($fp);
                echo "File: {$fileName} created\\n\\r";
                $progressBar->advance()->display();
                echo "\\n\\r";
                $progressBar->complete();
                $conn->close();
            }
        });

        $conn->send(json_encode([
            'type' => $type,
            'type_prod' => $type_prod,
            'format' => $format,
            'ext_cols' => $ext_cols,
            'cats' => $cats,
            'authToken' => $authToken
        ]));
        $progressBar->advance()->display();
        echo "\\n\\r";
    });
} catch (Exception $exception) {
    echo "Authorization Error! {$exception->getMessage()} \\n\\r";
}

class Auth
{
    private $secret = '{{secret}}'; //  Write secret key hear
    private $user_key = '{{user_key}}'; // Write user key hear
    protected $auth_url = '{{urlAuth}}'; // Write link to auth API

    function getAuthToken()
    {

        $array_query = [
            'user_key' => $this->user_key,
            'iat' => time(),
            'exp' => time() + 3 * 60
        ];

        $requestToken = JWT::encode($array_query, $this->secret); // Generation token
        $json = json_encode(['requestToken' => $requestToken]); // Array to json

        // Set all params from API
        $options = array(
            'http' => array(
                'method' => 'POST',
                'content' => $json,
                'header' => "Content-Type: application/json"
            )
        );

        // Send query in API
        $context = stream_context_create($options);
        $result = file_get_contents($this->auth_url, false, $context);

        // Send result
        return json_decode($result);
    }
}
        `,
        codeTxt: `// NodeJS get-price-ws example        
//-----------------------------------------------------------------------
// Installing
// $ mkdir files
// $ npm init
// $ npm install axios websocket fs jsonwebtoken progress
//

// Required packages
const axios = require('axios');
const WebSocketClient = require('websocket').client;
const fs = require('fs');
const ProgressBar = require('progress');
const jwt = require('jsonwebtoken')

//-----------------------------------------------------------------------
//Variables

const secret    = '{{secret}}';
const user_key  = '{{user_key}}';

const format    = '{{format}}';
const type      = '{{type}}';
const ext_cols = [{{ext_cols}}];
const cats = [{{cats}}];
const type_prod = [{{type_prod}}];
//-----------------------------------------------------------------------

const filePath  = './files/';
const fileName  = filePath+type+'_'+(new Date().toISOString().split('T')[0])+'_price.'+format;

let authToken = "";

const wsURL = '{{url}}';
const authURL = '{{urlAuth}}';

//-----------------------------------------------------------------------

(async () => {
// ---- GET authorization token
async function getAuthToken(){

    const requestToken = jwt.sign({
        user_key
    }, secret, {
        expiresIn: 3 * 60
    });

    return axios({
        method: 'POST',
        url: authURL,
        data: {
            requestToken
        }
    });
}

    try {
        console.log('Authorization...')
        const res = await getAuthToken()
        authToken = res.data.content.authToken
        const decoded = jwt.decode(authToken)
        console.log('OK')
    } catch (err) {
        console.log(\`Authorization Error! \${err}\`)
        process.exit(0)
    }

// ---- Web socket API connect

const client = new WebSocketClient(
  {
   maxReceivedMessageSize: 18388608,
   maxReceivedFrameSize: 11048576,
   closeTimeout: 30000,
  }
);

client.on('connectFailed', function(error) {
    console.log('Connect Error: ' + error.toString());
});

client.on('connect', connection => {
    console.log('WebSocket Client Connected');
    connection.on('error', error => {
        console.log("Connection Error: " + error.toString());
    });

    connection.on('close', () => {
        console.log('echo-protocol Connection Closed');
    });

    connection.on('message', message =>  {
        if (message.type === 'binary') {
            fs.writeFile(fileName, message.binaryData ,  (err,data) => {
                if (err) {
                    return console.log(err);
                    process.exit(0)
                }
                console.log(\`\\r                                                               \`);
                console.log(\`\\rFile \${fileName} created\`);
                process.exit(1)
            });
        }
        if (message.type === 'utf8') {
            try {
                const mes = JSON.parse(message.utf8Data);

                console.log(mes.message);
                if(mes.name == 'Error') {
                   process.exit(0)
                }
            } catch (error) {
                console.log("Error", error)
            }
        }
    });

    function sendRequest() {
        if (connection.connected) {
            console.log('API request. Process...')
            connection.sendUTF(JSON.stringify({
                        type,
                        type_prod,
                        format,
                        ext_cols,
                        cats,
                        authToken
            }));

            const bar = new ProgressBar('Process [:bar] :etas', { total: 40, incomplete: ' '});
            const timer = setInterval(function () {
                bar.tick();
                if (bar.complete) {
                   clearInterval(timer);
                }
            }, 500);

        }
    }
    sendRequest();

});
client.connect( wsURL ,'echo-protocol');
})();
`,
        format: 'json',
        type: 'regular',
        cats: [],
        ext_cols: [],
        formatItems: [{
                text: 'JSON',
                value: 'json'
            },
            {
                text: 'XML',
                value: 'xml'
            },
            {
                text: 'Excel',
                value: 'xlsx'
            },
        ],
        typeItems: [{
                text: 'Звичайний',
                value: 'regular'
            },
            {
                text: 'Безготівковий',
                value: 'bank'
            },
            {
                text: 'Контроль РРЦ',
                value: 'rrc'
            },
        ],
        type_prodItems: [{
                text: 'Новий',
                value: 'new'
            },
            {
                text: 'Дефект пакування (ДУ)',
                value: 'du'
            },
            {
                text: 'Уцінка',
                value: 'priceoff'
            },
        ],
        columns: [{
                name: 'Стан (опис Уцінки)',
                col: 'type',
            },
            {
                name: 'Артикул',
                col: 'artikul',
            },
            {
                name: 'Штрих-код',
                col: 'barcode'
            },
            {
                name: 'Гарантійний термін',
                col: 'warranty'
            },
            {
                name: 'Країна',
                col: 'country'
            },
            {
                name: 'РРЦ (без Промо), грн.',
                col: 'price_rrc_without_promo'
            },            
            {
                name: 'Ціна без ПДВ, грн.',
                col: 'price_wout_vat'
            },
            {
                name: 'Ставка ПДВ',
                // col: 'vat',
                col: 'vat_rate',
            },
            {
                name: 'Код ЗЕД',
                // col: 'fea_code'
                col: 'ukt'
            },
            {
                name: 'У пакуванні, шт.',
                // col: 'pack'
                col: 'scu'
            },
            {
                name: 'У коробці, шт.',
                // col: 'pack_box'
                col: 'scu_basket'
            },
            {
                name: 'У палеті, шт.',
                // col: ' pack_pallet'
                col: 'scu_palette'
            },
            {
                name: 'Вага, кг',
                col: 'weight'
            },
            {
                name: 'Об’єм, м3',
                col: 'volume'
            },
            {
                name: 'Серія',
                col: 'line'
            },
            {
                name: 'Опис скорочений',
                col: 'descr'
            },
            {
                name: 'Опис повний',
                col: 'url'
            },
            {
                name: 'Фото',
                col: 'photo'
            },
            {
                name: 'Фото (велике)',
                col: 'photo_b',
            },
            {
                name: 'Ексклюзив ЮК',
                col: 'exclusive'
            },
        ]
    }),
    props: {},
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'getCatalogTree', 'getSecretPare', 'postMethodRequest']),
        ...mapGetters(['catalogTree']),
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },
        create(type, format) {
            this.touch()
                .then(() => {
                    window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
                    this.fileBlobURL = ''
                    this.loaded = true
                    this.format = format
                    const user_id = this.contactInfo.userId
                })
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        saveCols() {
            this.ext_cols = this.columns.filter(el => el.selected == true).map(el => el.col)
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        saveCat() {
            this.cats = this.selection.map(el => el.id)
        },
        setOpened() {
            this.opened.push()
        },
        postMethod() {
            const requestToken = this.requestToken
            this.loaded = true
            this.postMethodRequest({
                    method: '/catalog/get-price',
                    params: {
                        format: 'json',
                        type: this.type,
                        cats: this.cats,
                        ext_cols: this.ext_cols
                    }
                })
                .then(res => {
                    this.setSnackbar(['success', '200 ОК'])
                    this.response = JSON.stringify(res, null, 4)
                    this.loaded = false
                })
                .catch(error => {
                    console.log(error)
                    this.setSnackbar(['error', error])
                    this.response = JSON.stringify(error.message, null, 4)
                    this.loaded = false
                })
        },
    },
    computed: {
        authToken() {
            return this.getLocalStorage('authToken')
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        secretPare() {
            return this.$store.state.main.secretPare
        },
        codetext() {
            let code = this.codeTxt
            const cats = this.cats
            const ext_cols = this.ext_cols
            code = code.replace(/\{\{secret\}\}/gi, (this.show1 ? this.secretPare.secret || 'треба згенерувати приватний ключ' : '***********************************'))
            code = code.replace(/\{\{user_key\}\}/gi, this.secretPare.user_key || 'треба згенерувати публічний ключ')
            code = code.replace(/\{\{cats\}\}/gi, cats)
            code = code.replace(/\{\{format\}\}/gi, this.format)
            code = code.replace(/\{\{type_prod\}\}/gi, this.type_prod.map(el => `'${el}'`))
            code = code.replace(/\{\{type\}\}/gi, this.type)
            code = code.replace(/\{\{ext_cols\}\}/gi, ext_cols.map(el => `'${el}'`))
            code = code.replace(/\{\{url\}\}/gi, this.url)
            code = code.replace(/\{\{urlAuth\}\}/gi, this.urlAuth)
            return code
        },
        codetextPhp() {
            let code = this.codeTxtPhp
            const cats = this.cats
            const ext_cols = this.ext_cols
            code = code.replace(/\{\{secret\}\}/gi, (this.show1 ? this.secretPare.secret || 'треба згенерувати приватний ключ' : '***********************************'))
            code = code.replace(/\{\{user_key\}\}/gi, this.secretPare.user_key || 'треба згенерувати публічний ключ')
            code = code.replace(/\{\{cats\}\}/gi, cats)
            code = code.replace(/\{\{format\}\}/gi, this.format)
            code = code.replace(/\{\{type_prod\}\}/gi, this.type_prod.map(el => `'${el}'`))
            code = code.replace(/\{\{type\}\}/gi, this.type)
            code = code.replace(/\{\{ext_cols\}\}/gi, ext_cols.map(el => `'${el}'`))
            code = code.replace(/\{\{url\}\}/gi, this.url)
            code = code.replace(/\{\{urlAuth\}\}/gi, this.urlAuth)
            return code
        },
        requestTxt() {
            let code = this.request
            code = code.replace(/\{\{authToken\}\}/gi, this.getLocalStorage('authToken'))
            code = code.replace(/\{\{format\}\}/gi, this.format)
            code = code.replace(/\{\{cats\}\}/gi, this.cats)
            code = code.replace(/\{\{type_prod\}\}/gi, this.type_prod.map(el => `'${el}'`))
            code = code.replace(/\{\{type\}\}/gi, this.type)
            code = code.replace(/\{\{ext_cols\}\}/gi, this.ext_cols.map(el => `'${el}'`))
            code = code.replace(/\{\{url\}\}/gi, this.url)
            code = code.replace(/\{\{urlAuth\}\}/gi, this.urlAuth)
            code = code.replace(/\'/g, '"')
            return code
        },
        response() {
            return this.format == 'json' ? `{
    "rests": {
        "datetime": "Mon Apr 05 2021 14:04:40 GMT+0300 (GMT+03:00)",
        "product": [
            {
                "cat_top": "КОМПЮТЕРНА ТЕХНІКА",
                "cat": "Ноутбуки 14 - 16 дюймів",
                "brand": "ACER",
                "id": "6544565",
                "name_ukr": "Ноутбук ACER Swift 3 SF314-42-R2BF (NX.HSEEU.007)",
                "price": 19036,
                "price_scu": 19036,
                "rrp": 20999,
                "rrp_control": 1,
                "status_region_1": 1,
                "qty_region_1": "10-50",
                "status_region_19": 0,
                "qty_region_19": "-",
                "status_sat_41": 0,
                "qty_sat_41": "-"
            }
        ]
    } 
}   
` : 'Blob Data'
        },
    },
    created() {
        this.touch()
            .then(() => {
                this.getSecretPare()
                this.getCatalogTree()
                    .then(data => {
                        this.items.push({
                            id: 0,
                            name: 'Виберіть категорію товарів:',
                            children: []
                        })
                        for (let idx in data.menuItems) {
                            const item = data.menuItems[idx]
                            this.items[0].children.push({
                                id: item.link,
                                name: item.text,
                                children: []
                            })
                            for (let i in data.menuSubItems) {
                                if (i == item.link) {
                                    const elements = data.menuSubItems[i]
                                    for (let id in elements) {
                                        const el = elements[id]
                                        this.items[0].children[idx].children.push({
                                            id: el.link,
                                            name: el.text,
                                            children: []
                                        })
                                        if (data.menuSubItems[el.link]) {
                                            const elements = data.menuSubItems[el.link]
                                            for (let el of elements) {
                                                this.items[0].children[idx].children[id].children.push({
                                                    id: el.link,
                                                    name: el.text,
                                                    children: []
                                                })
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    })
            })
        const local = []
        for (let i in this.columns) {
            if (local.find(el => this.columns[i].col == el)) {
                this.columns[i].selected = true
            } else {
                this.columns[i].selected = false
            }
            this.$set(this.columns, i, this.columns[i])
        }
        this.$store.commit('setLoading', false)
    }
}
</script>

<style>
</style>
